@import '../../styles/color.scss';
@import '../../styles/input.scss';
@import '../../styles/button.scss';
@import '../../styles/responsive.scss';

.container{
    padding: 5rem 6.125rem;
    width: 700px;
    box-sizing: border-box;
    max-height: 90vh;
    overflow-y: auto;
    box-sizing: border-box;
    scrollbar-width: none;

    @include lg{
        width: 90vw;
        max-width: 43.75rem;
    }

    @include md{
        padding: 2rem 2.5rem;
        box-sizing: border-box;
    }
}

.container::-webkit-scrollbar{
    display:none;
}

.msg{
    font-size: 1.125rem;
    color: $secondary-text-color;
    line-height: 2rem;

    @include md{
        font-size: 0.875rem;
    }
}

.phone{
    color: $highlight-text-color;
}

.inputGroup{
    @include inputGroup;
    height: 3.25rem;
    width: 100%;
    margin: 2.5rem auto;

    @include md{
        margin: 1.5em auto;
    }
    
}

.inputField{
    @include inputField;
    width: 100%;
    height: calc(100% - 0.3125rem);
    font-size: 1.125rem;
}

.inputField:focus ~ .floatingLabel,
.inputField:not(:focus):valid ~ .floatingLabel{
    @include floatingLabel-focus
}

.floatingLabel {
    @include floatingLabel;
    font-size: 1.125rem;
}

.errorMsg{
    color: $error-text-color;
    font-size: 0.875rem;
    margin-top: 0.5rem;
}

.notReceived{
    text-align: center;
    font-size: 1.125rem;
    color: $secondary-text-color;
    line-height: 1.5rem;

    @include md{
        font-size: 0.875rem;
    }
}

.resendButton{
    cursor: pointer;
    color: $primary-text-color;
}

.inactive{
    color: $disabled-text-color;
}

.confirmBtn{
    @include globalButton;
    width: 100%;
    background-color: $primary-btn-background-color;
    color: $primary-btn-text-color;
    font-size: 1.25rem;
    line-height: 1.5rem;
    border: none;
    padding: 0.875rem;
    margin: 3.125rem auto;
}

.backBtn{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.backIcon{
    margin-right: 0.845rem
}

.resendPopup{
    position: absolute;
    top: 50%;
    margin-top: -1.5rem;
    left: 50%;
    margin-left: -4.8125rem;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 0.5rem;
    padding: 0.75rem 1.25rem;
    color: $primary-btn-text-color;
    font-size: 1.125rem;
    line-height: 1.5rem;
}

.checkIcon{
    width: 0.8125rem;
    margin-right: 0.3125rem;
}