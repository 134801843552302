@mixin skeleton{
    content: '';
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 150px;
    background: linear-gradient(to right, rgba(255,255, 255, 0) 0%, #E8E8E8 50%, rgba(255,255, 255, 0) 100%);
    animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;

    @keyframes load {
        from {
            left: -150px;
        }
        to   {
            left: 100%;
        }
    }
    
}