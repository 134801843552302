@import '../../../styles/color.scss';

.container{
    position: fixed;
    z-index: 5;
    width: 90%;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: $primary-background-color;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2);
    overflow-y: scroll;
    scrollbar-width: none;
    -webkit-transition: left 0.2s, opacity 0.2s 0.1s, visibility 0s 0.2s;
    -moz-transition: left 0.2s, opacity 0.2s 0.1s, visibility 0s 0.2s;
    -ms-transition: left 0.2s, opacity 0.2s 0.1s, visibility 0s 0.2s;
    -o-transition: left 0.2s, opacity 0.2s 0.1s, visibility 0s 0.2s;
    transition: left 0.2s, opacity 0.2s 0.1s, visibility 0s 0.2s;
    left: -90%;
    display: block;
    opacity: 0;
    visibility: hidden;
   
    
}

.show{
    -webkit-transition: left 0.2s, opacity 0.2s, visibility 0s;
    -moz-transition: left 0.2s, opacity 0.2s, visibility 0s;
    -ms-transition: left 0.2s, opacity 0.2s, visibility 0s;
    -o-transition: left 0.2s, opacity 0.2s, visibility 0s;
    transition: left 0.2s, opacity 0.2s, visibility 0s;
    left: 0;
    opacity: 1;
    visibility: visible;
}

.container::-webkit-scrollbar{
    display: none;
}

.closeBtn{
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0 2em;
}

.closeIcon{
    width: 1.75rem;
    height: 1.75rem;
    object-fit: contain;
    margin-right: 1em;
}

.closeText{
    color: $secondary-text-color;
    font-size: 1.75rem;
    margin: 1.5em 0;
}

.searchContainer{
    padding: 0 2.5em 2em 2em;
}

.userMenu{
    margin-top: 1.5em;
    background-color: #f0f6fa;
    padding: 1.75em 1.5em
}

.userContainer{
    display: flex;
    align-items: center;
    margin-bottom: 1.5em;

    a{
        font-size: 0.875rem;
    }
}

.userAvator{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $primary-btn-background-color;
    color: $primary-btn-text-color;
    font-size: 1.25rem;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 100%;
    margin-right: 1em;
}

.username{
    margin: 0;
    font-size: 1.25rem;
    color: $secondary-text-color;
}

.profileLink{
    display: block;
    color: $secondary-text-color;
    font-size: 1.25rem;
    line-height: 1.5;
    margin: 1.5em 0;
    cursor: pointer;
}

.profileLink:last-of-type{
    margin-bottom: 0;
}

.menuItem{
    padding: 0.5em 2em;
    border-top: 1px solid $border-color
}

.menuItem:last-of-type{
    padding-bottom: 5rem;
}

.sectionTitle{
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.navText{
    color: $secondary-text-color;
    font-size: 1.5rem;
}

.highlightText{
    color: $primary-text-color;
    cursor: pointer;
}

.arrowIcon{
    width: 2rem;
    height: 2rem;
}

.subItemContainer{
    a{
        display: block;
        margin: 1.5em 0;
        color: $secondary-text-color;
        font-size: 1.25rem;
    }

    a:hover{
        font-weight: 600;
    }

    a:first-of-type{
        margin-top: 0;
    }
}


