@import '../../../styles/color.scss';
@import '../../../styles/responsive.scss';
@import '../../../styles/skeleton.scss';

.skeletonContainer{
    display: flex;
    justify-content: space-between;
}

.leftSkeleton{
    width: 10%;
    height: 30em;
    background-color: $skeleton-color;
    position: relative;
    overflow: hidden;

    @include lg{
        display: none;
    }
}

.leftSkeleton::after{
    @include skeleton;
}


.centerSkeleton{
    width: 70%;
    height: 30em;
    background-color: $skeleton-color;
    position: relative;
    overflow: hidden;

    @include lg{
        width: 100%;
        height: 15em;
    }
}

.centerSkeleton::before{
    @include skeleton;
}

.rightSkeleton{
    width: 10%;
    height: 30em;
    background-color: $skeleton-color;
    position: relative;
    overflow: hidden;

    @include lg{
        display: none;
    }
}

.rightSkeleton::after{
    @include skeleton;
}

.swiper{
    width: 100%;
    height: 25vw;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    overflow: hidden;

    @include lg{
        height: 40vw;
    }
}

.slide{

    img{
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}

.notActiveSlide{
    opacity: 0.5;
}

.arrowLeft{
    cursor: pointer;
    position: absolute;
    right: 2px;
    width: 2rem;
    height: 2rem;
    top: 50%;
    margin-top: -0.4375rem;
}

.arrowRight {
    cursor: pointer;
    position: absolute;
    left: 2px;
    width: 2rem;
    height: 2rem;
    top: 50%;
    margin-top: -0.4375rem;
}



.prevBannerImg{
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: right;
    opacity: 0.5;
}

.nextBannerImg{
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: left;
    opacity: 0.5;
}

.currentBanner{
    width: 100%;
    max-width: 83rem;
    height: 18.8%;
    max-height: 34.5rem;
    flex-shrink: 0;
    flex-grow: 1;
    position: relative;
}


.currentBannerImg{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.bullet{
    width: 2rem;
    height: 0.5rem;
    background-color: #000000;
    opacity: 0.3;
    margin: 0.625rem;
    cursor: pointer;
    display: inline-block;
}

.activeBullet{
    background-color: $primary-btn-background-color;
    opacity: 1;
    display: inline-block;
}

