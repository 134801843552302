@import '../../styles/color.scss';

.swiper-button-prev::after,
.swiper-button-next::after{
    font-size: 1.5rem;
}

.swiper-button-next,
.swiper-button-prev{
    color: $secondary-text-color !important;
}

.swiper-button-next{
    right: 5% !important;
}

.swiper-button-prev {
    left: 5% !important;
}

.swiper-slid{
    -webkit-transform: translate3d(0, 0, 0);
}