@import '../../../styles/color.scss';
@import '../../../styles/responsive.scss';

.container{
    display: flex;
    align-items: center;
    flex-direction: column;
}

.title{
    font-size: 2.5rem;
    line-height: 4.5rem;
    color: $secondary-text-color;
    margin-bottom: 1.5rem;

    @include lg{
        font-size: 1.5rem;
        line-height: 2.25rem;
        font-weight: 600;
    }
}

.divider{
    width: 6.25rem;
    height: 0.5rem;
    background-color: $primary-btn-background-color;

    @include lg{
        height: 5px;
        width: 3.125rem;
    }
}