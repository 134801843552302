@import '../../../styles/color.scss';
@import '../../../styles/responsive.scss';

.searchBar{
    width: 100%;
    position: relative;
}

.searchIconContainer{
    position: absolute !important;
    z-index: 1;
    object-fit: cover;
    right: 0.75em;
    top: 50%;
    margin-top: -0.75rem;
    color: $secondary-text-color;
    cursor: pointer;
    outline: none;
    border: none;
    background-color: transparent;
}

.searchIcon{
    width: 1.5rem;
    height: 1.5rem;
}

.inputField{
    border-radius: 1.25rem;
    border: 1.5px solid $border-color;
    width: 100%;
    height: 2.5rem;
    background-color: $search-background-color;
    color: $secondary-text-color;
    outline: none;
    padding-left: 1.5rem;
    font-size: 1rem;
    box-sizing: border-box;
}

.inputField::placeholder{
    color: $placeholder-color;
}