@import '../../styles/color.scss';
@import '../../styles/button.scss';
@import '../../styles/responsive.scss';

.container{
    padding: 5em 5.875em;
    box-sizing: border-box;
    width: 50rem;

    @include lg{
        padding: 10%;
        width: 90vw;
    }
}

.msg{
    font-size: 1.125rem;
    line-height: 2rem;
    color: $secondary-text-color;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.btn{
    @include globalButton;
    width: 100%;
    padding: 0.875rem 0;
    border: none;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.5rem;
    color: $primary-btn-text-color;
    background-color: $primary-btn-background-color;
    outline: none;
}