@import '../../styles/button.scss';
@import '../../styles/color.scss';
@import '../../styles/input.scss';
@import '../../styles/responsive.scss';

.container{
    padding: 5rem 8.875rem;
    width: 43.75rem;
    max-height: 90vh;
    overflow-y: auto;
    scrollbar-width: none;
    box-sizing: border-box;

    @include lg{
        width: 90vw;
        max-width: 43.75rem;
    }

    @include md{
        padding: 3rem 1.5rem;
        box-sizing: border-box;
        overflow-y: scroll;
        scrollbar-width: none;
    }
}

.container::-webkit-scrollbar{
    display:none;
}

.msg{
    color: $secondary-text-color;
    font-size: 1.125rem;
    line-height: 1.5rem;
    margin-top: 1.5rem;
    margin-bottom: 2.5rem;
}

.inputForm{
    display: flex;
    flex-direction: column;
    margin-bottom: 2.25rem;
}

.inputGroup{
    @include inputGroup;
    width: 100%;
    min-height: 3.25rem;
    margin-bottom: 2.75rem;
}

.inputField{
    @include inputField;
    width: 100%;
    height: calc(100% - 0.3125rem);
    font-size: 1.125rem;
    padding: 0.5rem 0;
}

.inputField:focus ~ .floatingLabel,
.inputField:not(:focus):valid ~ .floatingLabel{
    @include floatingLabel-focus
}

.floatingLabel{
    font-size: 1.125rem;
    @include floatingLabel
}

.errorMsg{
    color: $error-text-color;
    font-size: 0.875rem;
    line-height: 1rem;
    margin-top: 0.5rem;
}

.submitBtn{
    @include globalButton;
    color: $primary-btn-text-color;
    background-color: $primary-btn-background-color;
    margin-top: 2.5rem;
    width: 100%;
    padding: 0.875rem 0;
    border: none;
    outline: none;
}

.submitBtn:focus{
    outline: none;
}

.backBtn{
    display: flex;
    justify-content: center;
    align-items: center;
    color: $secondary-text-color;
    cursor: pointer;
}

.backIcon{
    height: 0.5rem;
    margin-right: 0.875rem;
}