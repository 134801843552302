@import "../../styles/color.scss";
@import "../../styles/button.scss";
@import '../../styles/responsive.scss';


.container{
    display: flex;
    flex-direction: column;
    max-height: 90vh;
    overflow-y: auto;
    box-sizing: border-box;
    scrollbar-width: none;

    @include lg{
        width: 90vw;
        max-width: 43.75rem;
        max-height: 90vh;
    }

}

.container::-webkit-scrollbar{
    display:none;
}

.coverImg{
    width: 700px;
    height: 320px;
    object-fit: cover;
    object-position: bottom center;

    @include md{
        width: 100%;
        height: auto;
        max-height: 12.5rem;
    }
}

.loginOptionContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 27rem;

    p{
        color: $secondary-text-color;
        font-size: 0.875rem;
        line-height: 1.5rem;
        margin: 0;
    }
}

.socialPlatformContainer{
    display: flex;
    justify-content: center;
    margin: 1.5rem auto;

    @include md{
        margin: 0.875em auto;
    }
}

.socialPlatformLogoContianer{
    height: 4rem;
    width: 4rem;
    cursor: pointer;
}

.socialPlatformLogoContianer:first-of-type{
    margin-right: 26px;
}

.divider{

    display: flex;
    align-items: center;

    width: 16.4375rem;
    box-sizing: border-box;
    text-align: center;
    color: $secondary-text-color;
    font-size: 0.875rem;
    line-height: 24px;
}

.divider::before, .divider::after{
    content: '';
    height: 1px;
    background-color: $divider-color;
    flex-grow: 1;
}

.divider::before{
    margin-right: 1rem;
}

.divider::after{
    margin-left: 1rem;
}

.buttonGroup{
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 1em;
    box-sizing: border-box;

    @include md{
        height: 10rem;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }
}

.button{
    @include globalButton;
    width: 15rem;
    height: 3.5rem;
    cursor: pointer;
    margin-top: 40px;

    @include md{
        width: 100%;
        max-width: 25rem;
        margin: 0;
    }
}

.registerBtn{
    color: $secondary-btn-text-color;
    background-color: $secondary-btn-background-color;
    border: 2px solid $secondary-btn-border-color;
    margin-right: 2rem;

    @include md{
        margin-right: 0;
    }
}
.loginBtn{
    @include globalButton;
    background-color: $primary-btn-background-color;
    color: $primary-btn-text-color;
    border: none;
}