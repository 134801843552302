@import '../../styles/responsive.scss';
@import '../../styles/color.scss';
@import '../../styles/skeleton.scss';

.container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;


    @include lg{
        width: 100%;
        overflow-x: hidden;
        margin-top: 1em;
    }
}

.desktop{
    display: flex;
    box-sizing: border-box;
    margin-top: 3rem;
    justify-content: center;
    gap: 1%;

    @include lg{
        display: none;
    }
}

@media screen and (max-width: 1280px) {
    .desktop{
        flex-wrap: wrap;
    }
}

.mobile{
    display: none;

    @include lg{
        display: flex;
        width: 165vw;
        flex-wrap: nowrap;
        flex-shrink: 0;
        justify-content: space-between;
    };

    @include md{
        width: 185vw
    }

    @include sm{
        width: 200vw
    }
}

.courseCard{
    width: calc(22vw - 1rem);
    height: 20vw;
    max-width: 25rem;
    margin: 1rem 0;
    background-color: $primary-background-color;
    box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1280px) {
        width: calc(45vw - 1rem);
        height: 30vw;
    }

    @include lg{
        padding: 0 0 0.5em;
        box-sizing: border-box;
        height: 40vw;
        width: 60vw;
    }

    @include md{
        height: 60vw;
    }

    @include sm{
        height: 70vw;
    }
}

.cover{
    width: 100%;
    height: 60%;
    object-fit: cover;
    background-color: $skeleton-color;
    overflow: hidden;
    position: relative;

    @include lg{
        height: 60%;
       
    }
}

.cover::before{
    @include skeleton;
}

.infoContainer{
    display: flex;
    flex-direction: column;
    padding: 0.5rem 1rem;
    height: 8rem;
}

.title{
    height: 1.5rem;
    width: 100%;
    background-color: $skeleton-color;
    overflow: hidden;
    position: relative;
}

.title::before{
    @include skeleton
}

.bottomContainer{
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-grow: 1;
    margin-top: 1.125rem;
    box-sizing: border-box;

    @include lg{
        margin-top: 1em;
    }
}

.bottomLeft{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    width: 50%;
}

.tutorBar{
    display: flex;
    align-items: center;
    width: 80%;
    height: 1rem;
    background-color: $skeleton-color;
    overflow: hidden;
    position: relative;
}

.tutorBar::before{
    @include skeleton;
}

.numberOfStudent{
    height: 0.875rem;
    width: 50%;
    background-color: $skeleton-color;
    overflow: hidden;
    position: relative;
}

.numberOfStudent::before{
    @include skeleton;
}

.priceContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    width: 50%;
}

.price{
    height: 1.125rem;
    width: 70%;
    background-color: $skeleton-color;
    overflow: hidden;
    position: relative;

    @include lg{
        height: 0.875rem;
    }
}

.price::before{
    @include skeleton;
}