@import '../../styles/color.scss';
@import '../../styles/responsive.scss';

.nav{
    background-color: $primary-background-color;
    height: 7rem;
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    border: 1px solid #E6E6E6;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2);
    z-index: 5;
    padding: 0 1em 0 2rem;

    @include lg{
        height: 3.5rem;
        padding: 0.5em 1em;
        z-index: 7
    }
}

.hide{
    display: none;
}

.navLeft{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.hamburgerMenu{
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 2em;
    display: none;
    cursor: pointer;

    @include lg{
        display: block;
        margin-right: 0.875em;
    }
}

.menuIcon{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.navBrand{
    height: 100%;
    margin: 0 !important;
}

.navBrand a img{
    height: 50px;

    @include lg{
        height: 1.75rem
    }
}

.navRight{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    width: 90%;
    max-width: 70rem;

    @include lg{
        max-width: 10rem;
    }
}

.navGroupLeft{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    @include lg {
        display: none;
    }
}

.navGroupMid{
    width: 25%;
    
    @include lg{
        display: none;
    }
}

.navGroupRight{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    margin-left: 2rem;

    @include lg{
        flex-grow: 1;
        justify-content: flex-end;
    }

    
}

.navItem{
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin: 0 20px;
    font-size: 1.375rem;
    line-height: 1.625rem;
    cursor: pointer;

    &:hover .navText{
        color: $hover-link-color;
        border-bottom: 1px solid $hover-link-color;

        @include lg{
            margin: 0 1em;
        }
    }

    @include lg{
        margin: 0 0 0 1em;
        justify-content: space-between;
    }

    @include xs{
        margin: 0 0 0 0.5em;
    }
}

.underline{
    color: $hover-link-color;
    border-bottom: 1px solid $hover-link-color;
}

.navCategory{
    height: 100%;
    position: relative;
    cursor: pointer;
}

.dropDownImg{
    width: 10px;
    margin: 5px;
}

.navCart{
    font-size: 25px;
    position: relative;
    @include lg{
        position: unset
    }
}


.navUser{
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $primary-btn-background-color;
    position: relative;

    @include lg{
        display: none;
    }
}

.firstLetter{
    color: $primary-btn-text-color;
    font-size: 1.25rem;
    line-height: 1.75rem;
}

.navIcon{
    height: 1.5rem;
}

.inboxContainer{
    position: relative;
}

.notification{
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 100%;
    background-color: $primary-btn-background-color;
    border: 0.1875rem solid $primary-btn-text-color;
    position: absolute;
    z-index: 2;
    right: 0;
    margin-top: -0.25rem;
    margin-right: -0.1875rem;
}
