@import '../../styles/button.scss';
@import '../../styles/color.scss';
@import '../../styles/input.scss';
@import '../../styles/responsive.scss';

.container{
    padding: 5em 5.875em;
    width: 43.75rem;
    box-sizing: border-box;

    @include lg {
        padding: 3em 5%;
        width: 90vw;
    }
}

.inputForm{
    margin-top: 2.5em;
}

.inputGroup{
    @include inputGroup;
    
    margin-bottom: 2.5rem;
}

.inputGroup:last-of-type{
    margin-bottom: 0
}

.inputField{
    @include inputField;
    border: none;
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: $secondary-text-color;
    border-bottom: 1.5px solid $border-color;
    width: 100%;
}

.floatingLabel{
    @include floatingLabel;
}

.inputField:focus ~ .floatingLabel,
.inputField:not(:focus):valid ~ .floatingLabel{
    @include floatingLabel-focus;
}

.addBtn{
    @include globalButton;
    width: 100%;
    padding: 0.875em 0;
    box-sizing: border-box;
    background-color: $primary-btn-background-color;
    color: $primary-btn-text-color;
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 600;
    outline: none;
    border: none;
    margin-top: 2.5em;
}

.error{
    color: $error-text-color;
    font-size: 0.875rem;
    margin: 0;
}