@import '../../styles/input.scss';
@import '../../styles/color.scss';
@import '../../styles//button.scss';
@import '../../styles/responsive.scss';

.container{
    width: 43.75rem;
    padding:  5rem 5.875rem;
    box-sizing: border-box;
    max-height: 90vh;
    position: relative;
    overflow-y: auto;

    @include lg{
        width: 90vw;
        max-width: 43.75rem;
    }

    @include md{
        padding: 3rem 1.5rem;
        box-sizing: border-box;
        overflow-y: scroll;
        scrollbar-width: none;
    }
}

.container::-webkit-scrollbar{
    display:none;
}

.titleBar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.loginText{
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: $secondary-text-color;
}

.highlightedText{
    color: $highlight-text-color;
    cursor: pointer;
}

.formContainer{
    min-height: 30rem;
}

.registerForm{
    display: flex;
    flex-direction: column;
    margin-top: 3.25rem;
}

.inputGroup{
    @include inputGroup;
    width: 100%;
    margin-top: 2.75rem;
}

.inputGroup:first-of-type{
    margin-top: 0;
}

.rowFlex{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include md{
        flex-wrap: wrap;
    }
}

.inputSubGroup{
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    position: relative;

    @include md{
        margin-bottom: 2em;
    }
}

.inputSubGroup:first-of-type{
    margin-right: 2em;

    @include md{
        margin-right: 0;
    }
}

.areaCodeGroup{
    flex-basis: 20%;
    border-bottom: 1.5px solid $border-color;
    display: flex;
    justify-content: center;
}

.areaCodeText{
    @include floatingLabel;
    @include floatingLabel-focus;
}

.areaSearchContainer{

    display: flex;
    padding: 0.5rem;
    box-sizing: border-box;
    width: 100%;
    border-bottom: 2px solid $primary-text-color;
    align-items: center;


    label{
        flex-shrink: 0;
        color: $secondary-text-color;
        font-size: 1rem;
    }
    
    input{
        outline: none;
        border: none;
        font-size: 1rem;
        padding: 0;
        margin: 0;
        line-height: 1.25rem;;
    }
}

.dropdownBtn{
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    p{
        margin: 0;
        font-size: 1.125rem;
        color: $secondary-text-color
    }
}

.dropdownIcon{
    width: 0.625rem !important;
}

.dropdown{
    background-color: #ffffff;
    position: absolute;
    height: 13rem;
    top: 2rem;
    overflow-y: auto;
    width: 12rem;
    z-index: 3;
    box-shadow: 0 0 0.625rem #666;
    padding: 0.5rem;
    -ms-overflow-style: none;
    scrollbar-width: none;
    border-radius: 0.25rem;
}

.dropdown::-webkit-scrollbar{
    display: none;
}

.dropdownOptions{
    border-bottom: 1px solid $border-color;
    color: $secondary-text-color;
    padding: 0.5rem;
}

.dropdownOptions:hover{
    background-color: $primary-btn-background-color;
    color: $primary-btn-text-color;
}

.phoneGroup{
    flex-grow: 1;
    margin-left: 2rem;

    @include md{
        margin-left: 0;
        flex-basis: 78%;
        flex-grow: 0
    }
}

.inputField{
    @include inputField;
    width: 100%;
    font-size: 1.125rem;
    padding: 0.5rem 0;
    color: $secondary-text-color !important
}

.floatingLabel{
    @include floatingLabel;
    font-size: 1.125rem;
    margin-top: -0.5rem;
}

.inputField:focus ~ .floatingLabel,
.inputField:not(:focus):valid ~ .floatingLabel {
    @include floatingLabel-focus
}

.reminderText{
    color: $disabled-text-color;
    font-size: 0.8125rem;
    line-height: 1rem;
}

.errorText{
    color: $error-text-color;
    font-size: 0.875rem;
    line-height: 1rem;
    margin-top: 0.5rem;
}

.checkboxContainer{
    display: flex;
    width: 100%;
    margin-top: 1rem;
    align-items: center;

    @include md {
        align-items: flex-start;
    }

}

.checkbox{
    width: 1.375rem;
    height: 1.375rem;
    border: 1px solid $border-color;
    border-radius: 0.25rem;

    @include md{
        margin-top: 0.25em;
        width: 1.25rem;
        height: 1.25rem;
    }
}

.checked{
    width: 100%;
    height: 100%;
    background-color: $primary-btn-background-color;
    object-fit: contain;
    border-radius: 0.25rem;

}

.checkboxLabel{
    margin-left: 1rem;
    font-size: 1.125rem;
    color: $secondary-text-color;
    line-height: 1.5rem;
    flex-basis: 80%;

    @include md{
        font-size: 0.875rem;
    }
}

.link{
    text-decoration: underline;
}

.registerBtn{
    @include globalButton;
    width: 100%;
    padding: 0.875rem;
    color: $primary-btn-text-color;
    background-color: $primary-btn-background-color;
    border: none;
    outline: none;
    margin-top: 2.5rem;
    margin-bottom: 2.25rem;
    font-size: 1.25rem;
    line-height: 1.5rem;
}

.backBtn{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: $secondary-text-color;
    cursor: pointer;
}
.backIcon{
    height: 0.5rem !important;
    margin-right: 0.875rem;
}