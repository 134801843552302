@import '../../styles/color.scss';
@import '../../styles//button.scss';
@import '../../styles/responsive.scss';

.container{
    width:  43.75rem;
    padding: 5rem 5.875rem;
    box-sizing: border-box;

    @include lg{
        width: 90vw;
        max-width: 43.75rem;
    }

    @include md{
        padding: 3rem 1.5rem;
        box-sizing: border-box;
        overflow-y: scroll;
        scrollbar-width: none;
    }

    
}

.container::-webkit-scrollbar{
    display:none;
}

.msg{
    color: $secondary-text-color;
    line-height: 2rem;
    font-size: 1.125rem;
    margin-top: 1.5rem;
    margin-bottom: 2.5rem;

    @include md{
        font-size: 0.875rem;
    }
}

.confirmBtn{
    @include globalButton;
    width: 100%;
    color: $primary-btn-text-color;
    background-color: $primary-btn-background-color;
    font-size: 1.25rem;
    line-height: 1.5rem;
    padding: 0.875rem 0;
    border: none;
    outline: none;
}

