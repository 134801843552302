$background: #ffffff;
$primary-background-color: #ffffff;
$secondary-background: #EDEDED;
$light-blue-background-color: #F0F6FA;
$transparent-blue-background-color: rgba(43,186,238, 0.2);
$highlight-background-color: #FF6C40;
$dark-background: #242424;
$danger-background: #F30102;
$transparent-background: rgba(255, 255, 255, 0.8);
$light-grey-background-color: #F5F5F5;
$skeleton-color: #9e9e9e59;

//-------------- text color ------------------------

$primary-text-color: #2BBAEE;
$secondary-text-color: #363636;
$highlight-text-color: #FF6C40;
$light-text-color: #ffffff;
$disabled-text-color: #9E9E9E;
$error-text-color: #FF4D50;
$danger-text: #8F2F44;
$primary-dark-text: #000000;
$primary-light-text: #ffffff;
$hover-link-color: #225074;
$dark-blue-text-color: #225074;

$border-color: #E6E6E6;

$disabled-color: #9E9E9E;

//-------------- btn color ------------------------

//1.background
$primary-btn-background-color: #2BBAEE;
$secondary-btn-background-color: #ffffff;
$dark-blue-btn-background: #225074;
//2. text
$primary-btn-text-color: #ffffff;
$secondary-btn-text-color: #225074;
//3. border
$secondary-btn-border-color: #225074;

//---------------- Divider -----------------------
$divider-color: #E6E6E6;

//---------------- PlaceHolder -------------------
$placeholder-color: #9E9E9E;

//---------------- Checkbox ----------------------
$checkbox-color: #9E9E9E;


//---------------- Footer ------------------------
$footer-background: #F0F6FA;


//dropdown
$dropdown-option-hover-color: #F5F5F5;

//Search
$search-background-color: #F5F5F5;