@import '../../styles/color.scss';
@import '../../styles/button.scss';
@import '../../styles/responsive.scss';
@import '../../styles/skeleton.scss';

.cardContainer{
    width: calc(22vw - 1rem);
    max-width: 25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
    height: 100%;
    
    @media screen and (max-width: 1300px) {
        width: 40vw;
        max-width: 40vw;
        margin-left: auto;
        margin-right: auto;    
    }

    @include lg{
        margin: 0;
        padding: 0.5em 0;
        box-sizing: border-box;
        width: 100%;
        max-width: 100%;
    }
}



.card{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: $primary-background-color;
    box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.15);
    position: relative;
    justify-content: space-between;
    overflow: hidden;

    >a{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    @include lg{
        flex-shrink: 0;
        height: 100%;
        width: 100%;
    }
}

.coverContianer{
    height: 13vw;

    @media screen and (max-width: 1300px) {
        height: 25vw;  
    }

    @include lg{
        height: 36vw;
    }
}

.largerCoverContainer{
    @include lg{
        height: 50vw;
    }
}

.cover{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.hidden{
    display: none;
}

.coverSkeleton{
    width: 100%;
    background-color: $skeleton-color;
    position: relative;
    overflow: hidden;
    height: 13vw;

    @media screen and (max-width: 1300px) {
        height: 19vw;  
    }

    @include lg{
        height: 50vw;
    }

}
.coverSkeleton::before{
    @include skeleton;
}

.enrolledBadge{
    position: absolute;
    background-color: $primary-background-color;
    color: $dark-blue-text-color;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    border-radius: 0.75rem;
    padding: 0.1875rem 0.75rem;
    font-size: 0.8125rem;
    line-height: 1.125rem;
    top: 1rem;
    left: 1.5rem;
    box-sizing: border-box;
}

.infoContainer{
    display: flex;
    flex-direction: column;
    padding: 0.5rem 1rem;
    height: 8rem;
}

.title{
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.375rem;
    text-align: left;
    margin: 0;
    color: $secondary-text-color;

    @include xs{
        font-size: 0.875rem;
    }
}

.bottomContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    flex-grow: 1;
    margin-top: 1.125rem;
    box-sizing: border-box;
}


.bottomTopContainer, .bottomBottomContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tutorBar{
    display: flex;
    align-items: center;
}

.tutorAvator{
    border-radius: 100%;
    width: 2rem;
    height: 2rem;
    margin-right: 0.5rem;
    object-fit: cover;

    @include lg{
        width: 1.5rem;
        height: 1.5rem;
    }
}

.tutorName{
    color: $primary-text-color;
    font-size: 1rem;
    line-height: 1.375rem;
    margin: 0;

    @include md{
        font-size: 0.75rem;
    }

    @include xs{
        font-size: 0.625rem;
    }
}

.numberOfStudent{
    color: $secondary-text-color;
    font-size: 0.8125rem;
    line-height: 1.125rem;
    margin: 0;
    display: flex;
    align-items: center;

    @include lg{
        font-size: 0.75rem;
    }

    @include xs{
        font-size: 0.625rem;
    }
}

.studentIcon{
    width: 1rem;
    height: 1rem;
}

.priceContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
}

.normalPriceContainer{
    justify-content: flex-end;
}

.calculatedDiscount{
    color: $primary-btn-text-color;
    background-color: $highlight-background-color;
    border-radius: 0.75rem;
    font-size: 0.8125rem;
    padding: 0.1875rem 0.8125rem;
    margin: 0;
    height: 1.25rem;

    @include md{
        font-size: 0.75rem;
        padding: 0.25em 0.5em;
    }
    @include xs{
        font-size: 0.625rem;
    }
    
}

.discount{
    color: $primary-text-color;
    font-size: 1rem;
    margin: 0;
    font-weight: 600;
    line-height: 1.3125rem;

    @include md{
        font-size: 0.75rem;
        text-align: right;
    }

    @include xs{
        font-size: 0.625rem;
    }
}

.price{
    color: $secondary-text-color;
    font-size: 1rem;
    line-height: 1.3125rem;
    margin-left: auto;
    margin-top: 0.1875rem;
    margin-bottom: 0;
    font-weight: 600;
    position: relative;

    @include md{
        font-size: 0.75rem;
    }

    @include xs{
        font-size: 0.625rem;
    }
}

.crossOutPrice{
    position: absolute;
    height: 2px;
    background-color: $secondary-text-color;
    top: 50%;
    left: 0;
    left: -5%;
    margin-top: -1.5px;
    width: 110%;
}

.tbc{
    color: $primary-text-color;
    font-size: 1rem;
    margin: 0;
    margin-left: auto;
    font-weight: 600;
    line-height: 1.3125rem;
}