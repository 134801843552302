@import '../../styles/input.scss';
@import '../../styles/color.scss';
@import '../../styles//button.scss';
@import '../../styles/responsive.scss';


.container{
    width: 43.75rem;
    max-height: 90vh;
    overflow-y: auto;
    padding:  5rem 5.875rem;
    box-sizing: border-box;

    @include lg{
        width: 90vw;
        padding: 5% 5%;
        max-height: 90vh;
        overflow-y: auto;
    }
}

.msg{
    margin-top: 1.625rem;
    margin-bottom: 2.5rem;
    color: $secondary-text-color;
    font-size: 1.125rem;
    line-height: 2rem;

    @include lg{
        font-size: 0.875rem;
        line-height: 1.25rem;
    }
}

.inputForm{
    display: flex;
    flex-direction: column;
    margin-top: 3.25rem;

    @include lg{
        margin-top: 1.5em;
    }
}

.inputGroup{
    @include inputGroup;
    width: 100%;
    margin-top: 2.75rem;

    @include lg{
        margin-top: 1.5em;
    }
}

.inputGroup:first-of-type{
    margin-top: 0;
}

.rowFlex{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.inputSubGroup{
    display: flex;
    flex-direction: column;
    flex-basis: 45%;
    position: relative;
}

.areaCodeGroup{
    flex-basis: 20%;
    border-bottom: 1.5px solid $border-color;
    height: 3.6rem;
    display: flex;
    justify-content: center;

    @include lg{
        height: 3em;
    }
}

.areaSearchContainer{

    display: flex;
    padding: 0.5rem;
    box-sizing: border-box;
    width: 100%;
    border-bottom: 2px solid $primary-text-color;
    align-items: center;


    label{
        flex-shrink: 0;
        color: $secondary-text-color;
        font-size: 1rem;
    }
    
    input{
        outline: none;
        border: none;
        font-size: 1rem;
        padding: 0;
        margin: 0;
        line-height: 1.25rem;;
    }
}

.areaCodeText{
    @include floatingLabel;
    @include floatingLabel-focus;
}

.dropdownBtn{
    display: flex;
    align-items: center;
    cursor: pointer;
}

.selectedAreaCode{
    margin-top: 0.1875rem;
    margin-bottom: 0.5rem;
}

.dropdownIcon{
    width: 0.625rem !important;
    margin-left: 1.5rem;
}

.dropdown{
    background-color: #ffffff;
    position: absolute;
    height: 13rem;
    top: 3rem;
    overflow-y: auto;
    width: 12rem;
    z-index: 3;
    box-shadow: 0 0 0.625rem #666;
    padding: 0.5rem;
    -ms-overflow-style: none;
    scrollbar-width: none;
    border-radius: 0.25rem;
}

.dropdown::-webkit-scrollbar{
    display: none;
}

.dropdownOptions{
    border-bottom: 1px solid $border-color;
    color: $secondary-text-color;
    padding: 0.5rem;
}

.dropdownOptions:hover{
    background-color: $primary-btn-background-color;
    color: $primary-btn-text-color;
}

.phoneGroup{
    flex-grow: 1;
    margin-left: 2rem;
}

.inputField{
    @include inputField;
    width: 100%;
    height: 2.5rem;
    font-size: 1.125rem;
    padding: 0.5rem 0;

    @include lg{
        font-size: 1rem;
        height: 2rem;
        padding: 0.5em 0;
    }
}

.inputField:disabled{
    background-color: #ffffff;
    color: $disabled-color;
}

.floatingLabel{
    @include floatingLabel;
    font-size: 1.125rem;
    margin-top: -0.5rem;

    @include lg{
        font-size: 1em;
        margin-top: -1em;
    }
}

.inputField:focus ~ .floatingLabel,
.inputField:not(:focus):valid ~ .floatingLabel {
    @include floatingLabel-focus
}

.reminderText{
    color: $disabled-text-color;
    font-size: 0.8125rem;
    line-height: 1rem;
    display: block;

    @include lg{
        font-size: 0.75em;
    }
}


.errorText{
    color: $error-text-color;
    font-size: 0.875rem;
    line-height: 1rem;
    margin-top: 0.5rem;
}

.finishBtn{
    @include globalButton;
    width: 100%;
    padding: 0.875rem;
    color: $primary-btn-text-color;
    background-color: $primary-btn-background-color;
    border: none;
    outline: none;
    margin-top: 2.5rem;
    margin-bottom: 2.25rem;
    font-size: 1.25rem;
    line-height: 1.5rem;
}

.backBtn{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: $secondary-text-color;
    cursor: pointer;
}
.backIcon{
    height: 0.5rem !important;
    margin-right: 0.875rem;
}