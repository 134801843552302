@import '../../styles/color.scss';
@import '../../styles/button.scss';
@import '../../styles/responsive.scss';

.container{
    padding: 5em 5.875em;
    width: 43.75rem;
    box-sizing: border-box;

    @include lg{
        padding: 3em 5%;
        width: 90vw;
    }
}

.msg{
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: $secondary-text-color;
    margin-top: 1.5em;
}

.confirmBtn{
    @include globalButton;
    background-color: $primary-btn-background-color;
    color: $primary-btn-text-color;
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 600;
    padding: 0.875em 0;
    width: 100%;
    box-sizing: border-box;
    outline: none;
    border: none;
    margin-top: 2.5em;
}