@import '../../styles/color.scss';
@import '../../styles/button.scss';
@import '../../styles/responsive.scss';

.container{
    padding: 5em 5.875em;
    box-sizing: border-box;
    width: 43.75rem;

    @include lg{
        padding: 10%;
        width: 90vw;
    }
}

.msg{
    font-size: 1.125rem;
    line-height: 2rem;
    color: $secondary-text-color;
    margin-top: 1.5rem;
    margin-bottom: 0;
}

.btnGroup{
    display: flex;
    margin-top: 2.5rem;

    @include lg{
        flex-direction: column;
    }
}

.certificateBtn{
    @include globalButton;
    width: 100%;
    padding: 0.875rem 0;
    border: 2px solid $secondary-btn-border-color;
    background-color: transparent;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.5rem;
    outline: none;
    margin-right: 2rem;
    color: $dark-blue-text-color;

    @include lg{
        margin-bottom: 1em;
    }
}

.homeBtn{
    @include globalButton;
    width: 100%;
    padding: 0.875rem 0;
    border: none;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.5rem;
    color: $primary-btn-text-color;
    background-color: $primary-btn-background-color;
    outline: none;
}