@import '../../styles/input.scss';
@import '../../styles/color.scss';
@import '../../styles//button.scss';
@import '../../styles/responsive.scss';

.container{
    width: 100%;
    padding: 0 5.625rem 10rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include lg{
        padding: 2.5em 1.5em;
    }
}

.topPadding{
    padding-top: 8rem;

    @include lg{
        padding-top: 2.625rem;
    }
}

.largeTitle{
    color: $secondary-text-color;
    font-size: 2.5rem;
    line-height: 5rem;
    font-weight: 600;
    width: 100%;

    @include lg{
        font-size: 1.875rem;;
        line-height: normal;
        font-weight: 500;
        margin: 0;
    }
}

.smallTitle{
    color: $secondary-text-color;
    font-size: 1.875rem;
    line-height: 2.625rem;
    width: 100%;
}

.content{
    display: flex;
    width: 100%;
    justify-content: space-between;

    @include lg{
        flex-direction: column;
    }
}

.contactForm{
    display: flex;
    flex-direction: column;
    margin-top: 3.25rem;
    width: 33.33%;
    flex-shrink: 1;

    @include lg{
        width: 100%;
    }
}

.inputGroup{
    @include inputGroup;
    width: 100%;
    margin-top: 2.75rem;
}

.inputGroup:first-of-type{
    margin-top: 0;
}

.disabledField{
    opacity: 0.4;

    .dropdownBtn{
        cursor: default;
    }
}

.dropdownBtn{
    display: flex;
    align-items: center;
    cursor: pointer;
    border-bottom: 1.5px solid $border-color;
    padding: 0.5rem;
}

.selectedAreaCode{
    margin-top: 0.1875rem;
    margin-bottom: 0.5rem;
}

.dropdownIcon{
    width: 0.625rem !important;
    margin-left: auto;
}

.dropdown{
    background-color: #ffffff;
    position: absolute;
    height: 20rem;
    top: 3.5rem;
    overflow-y: auto;
    width: 15rem;
    z-index: 3;
    box-shadow: 0 0.125rem 0.9375rem 0 rgba(0, 0, 0, 0.2);
    -ms-overflow-style: none;
    scrollbar-width: none;
    border-radius: 0.25rem;
}

.dropdown::-webkit-scrollbar{
    display: none;
}

.dropdownOptions{
    color: $secondary-text-color;
    padding: 1rem;
    font-size: 1.25rem;
    line-height: 1.75rem;
    cursor: pointer;
}

.dropdownOptions:hover{
    background-color: $dropdown-option-hover-color;
}

.typeText{
    @include floatingLabel;
    @include floatingLabel-focus;
}


.inputField{
    @include inputField;
    width: 100%;
    height: 2.5rem;
    font-size: 1.125rem;
    padding: 0.5rem 0;
    resize: none;
}

.inputField:disabled{
    background-color: #ffffff;
    color: $disabled-color;
}

.floatingLabel{
    @include floatingLabel;
    font-size: 1.125rem;
    margin-top: -0.5rem;
}

.inputField:focus ~ .floatingLabel,
.inputField:not(:focus):valid ~.floatingLabel,
.inputField:disabled ~.floatingLabel {
    @include floatingLabel-focus
}

.btnGroup{
    display: flex;
    flex-wrap: wrap;
    margin-top: 2.5rem;
    margin-bottom: 2.25rem;
    align-items: center;
    justify-content: space-between;
}


.submitBtn{
    @include globalButton;
    width: 100%;
    max-width: 23.5rem;
    padding: 0.875rem 0;
    color: $primary-btn-text-color;
    background-color: $primary-btn-background-color;
    border: none;
    outline: none;
    font-size: 1.25rem;
    line-height: 1.5rem;

    @include lg{
        max-width: 100%;
    }
}

.disabledBtn{
    background-color: $border-color;
    color: $disabled-color;
    cursor: default;
}

.submittedText{
    color: $secondary-text-color;
    font-size: 0.875em;
    line-height: 1.5rem;
}

.contactGroup{
    display: flex;
    flex-direction: column;
}

.infoGroup{
    margin-bottom: 1.5rem;
}

.fieldName{
    font-size: 1.125rem;
    color: $secondary-text-color;
    line-height: 1.5rem;
    margin-bottom: 0.75rem;
    font-weight: 600;
}

.fieldContent{
    font-size: 1.125rem;
    color: $secondary-text-color;
    line-height: 1.5rem;
    margin: 0;
     
    a{
        color: $primary-text-color;
        text-decoration: underline;
    }
}

.socialIcon{
    margin-right: 1.5rem;
    height: 1.125rem;
    cursor: pointer;
}