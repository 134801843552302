@import './color.scss';
@import './responsive.scss';

html, body{
    width: 100%;
    height: 100%;
    background-color: $background;
    margin: 0;
    padding: 0;
    color: $secondary-text-color;
    font-family: 'Noto Sans TC', sans-serif !important;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#root{
    height: 100%;
}

a{
    text-decoration: none;
    color: $secondary-text-color;
}

img{
    color: transparent
}

.App{
    display: flex;
    flex-direction: column;
    height: 100%;
}

.noMargin{
    margin-top: 0;
}

.main{
    margin-top: 7rem;
    flex-grow: 1;

    @include lg{
        margin-top: 4.5rem;
    }
}

button:focus{
    outline: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active
{
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
}

textarea{
    font-family: 'Noto Sans TC', sans-serif !important;
}