@import '../../styles/responsive.scss';


.coursesListContainer{
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    margin-top: 3rem;
    // gap: 1.67%;

    @media screen and (max-width: 1300px) {
        margin-left: auto;
        margin-right: auto;
    }

    @include lg{
        display: none;
    }
}

.coursesListContainer > * + * {
    margin-left: 1.67%;
}

.coursesListContainer > * + *:nth-of-type(odd){
    @media screen and (max-width: 1300px) {
        margin-left: 0
    }
}



.swiper{
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    overflow: hidden;
    margin-top: 1.5em;
    display:none;
    position: relative;
    @include lg{
        display: block
    }
}

.slide{
    height: auto;
    display: flex;
    justify-content: center;
}

.leftArrow{
    width: 2.375rem;
    height: 4rem;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 10%;
    top: 50%;
    margin-top: -2em;
    background-color: #ffffff;
    z-index: 2;
    cursor: pointer;

    img{
        width: 2rem;
        height: 2rem;
        object-fit: cover;
    }
}

.rightArrow{
    width: 2.375rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: #ffffff;
    right: 10%;
    top: 50%;
    z-index: 2;
    margin-top: -2em;
    cursor: pointer;

    img{
        width: 2rem;
        height: 2rem;
        object-fit: cover;
    }
}

.hiddenArrow{
    display: none;
}

