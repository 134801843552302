@import '../../styles/responsive.scss';
@import '../../styles/color.scss';
@import '../../styles/button.scss';

.container{
    width: 43.75rem;
    max-height: 90vh;
    display: flex;
    position: relative;
    flex-direction: column;
    overflow-y: auto;

    @include lg{
        width: 90vw;
        max-height: 90vh;
    }
}

.copiedText{
    position: absolute;
    width: 10rem;
    padding: 0.875em;
    font-size: 1.5rem;
    margin: 0;
    text-align: center;
    border-radius: 1.5rem;
    background-color: $border-color;
    color: $secondary-text-color;
    top: 50%;
    left: 50%;
    margin-top: -1.5rem;
    margin-left: -5.875rem;
}

.show{
    visibility: visible;
    opacity: 1;
    transition: visibility 0s linear 0s, opacity 300ms;
}

.hide{
    visibility: hidden;
    opacity: 0;
    transition:  visibility 0s linear 300ms, opacity 300ms;
}

.promoImg{
    width: 100%;
    max-height: 40vh;
    object-fit: cover;
}

.promoInfo{
    padding: 2em 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;

    @include lg{
        padding: 5% 10%;
    }
}

.title{
    color: $secondary-text-color;
    font-size: 2.1875rem;
    font-weight: 600;
    margin: 0;

    @include lg{
        font-size: 1.25rem;
    }
}

.msg{
    color: $secondary-text-color;
    font-size: 1.125rem;
    margin: 1em 0;

    @include lg{
        font-size: 0.875rem;
        margin: 5% 0 0;
    }
}

.codeContainer{
    padding: 1em 0;
    width: 15em;
    border-bottom: 2px solid $border-color;
    margin: 1em 0 2.5em;

    @include lg{
        margin: 5% 0;
    }
}

.code{
    color: $dark-blue-text-color;
    font-size: 1.875rem;
    font-weight: 600;
    margin: 0;
    text-align: center;

    @include lg{
        font-size: 1.25rem;
    }
}

.buttonContainer{
    padding: 0 1.25em;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    @include lg{
        flex-direction: column
    }
}

.copyBtn{
    @include globalButton;
    color: $secondary-btn-text-color;
    background-color: $secondary-btn-background-color;
    border: 1px solid $secondary-btn-border-color;
    outline: none;
    width: 45%;
    padding: 0.875em 0;
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 600px;

    @include lg{
        font-size: 1rem;
        width: 100%;
        margin-bottom: 0.5em;
    }
}

.selectCourseBtn{
    @include globalButton;
    color: $primary-btn-text-color;
    background-color: $primary-btn-background-color;
    border: none;
    outline: none;
    width: 45%;
    padding: 0.875em 0;
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 600;

    @include lg{
        font-size: 1rem;
        width: 100%;
    }
}

@media screen and (max-height: 700px) {

    .title{
        font-size: 1.5rem;
    }

    .msg{
        font-size: 1rem;
    }

    .codeContainer{
        padding: 0.5em 0;
        margin: 0.5em 0 1em;
    }

    .code {
        font-size: 1.25rem;
    }

    .buttonContainer button{
        font-size: 0.875rem;
    }

    
}
